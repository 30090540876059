import * as React from 'react'
import { useState, useEffect } from 'react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { XCircleIcon } from '@heroicons/react/outline'

const Notification = (props) => {
  const { toastList } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, 6000);

    return () => {
      clearInterval(interval);
    }

  }, [toastList, list]);

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = toastList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  }

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {
            list.map((toast, i) =>
              <div key={i} className="max-w-sm w-full bg-blanc shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {toast.icon === 'check' ? <CheckCircleIcon className="h-6 w-6 text-violet" aria-hidden="true" /> : <XCircleIcon className="h-6 w-6 text-violet" />}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gris">{toast.title}</p>
                      <p className="mt-1 text-sm text-gris">{toast.description}</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button onClick={() => deleteToast(toast.id)}
                        className="bg-white rounded-md inline-flex text-gris hover:text-gris focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gris"
                      >
                        <span className="sr-only">Fermer</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default Notification;